<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
                <b-row>
                    <b-col cols="12">
                        <validation-provider #default="validationContext" name="Eski Şifre" rules="required">
                            <b-form-group label="Eski Şifre">
                                <b-form-input placeholder="Eski Şifre" v-model="dataInfo.oldPassword" type="password" :state="getValidationState(validationContext)" trim/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12">
                        <validation-provider #default="validationContext" name="Yeni Şifre" rules="required">
                            <b-form-group label="Yeni Şifre">
                                <b-form-input placeholder="Yeni Şifre" v-model="dataInfo.newPassword" type="password" :state="getValidationState(validationContext)" trim/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12">
                        <validation-provider #default="validationContext" name="Yeni Şifre Tekrar" rules="required">
                            <b-form-group label="Yeni Şifre Tekrar">
                                <b-form-input placeholder="Yeni Şifre Tekrar" v-model="dataInfo.newPasswordAgain" type="password" :state="getValidationState(validationContext)" trim/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <action-buttons :back-route="'home'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {onUnmounted, ref} from '@vue/composition-api'
import {email, required} from "@core/utils/validations/validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import Overlay from "@/components/Overlay.vue";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import storeModule from "@/views/common/account/store";
import {toastMessage} from "@core/utils/utils";
import {$themeConfig} from "@themeConfig";

export default {
    components: {
        BFormInvalidFeedback,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,

        Overlay,
        ActionButtons,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        const toast = useToast()
        const busy = ref(false)

        const dataInfo = ref({
            oldPassword: '',
            newPassword: '',
            newPasswordAgain: ''
        })

        const onSubmit = () => {
            if (!$themeConfig.app.appIsDemo) {
                busy.value = true
                store.dispatch('store/changePassword', dataInfo.value).then(response => {
                    toastMessage(toast, 'success', response.data.message)
                    resetForm()
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                }).finally(message => {
                    busy.value = false
                })
            } else {
                toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
            }
        }

        return {
            refFormObserver,
            busy,

            dataInfo,

            onSubmit,
            getValidationState,
            resetForm,
        }
    },
}
</script>
