<template>
    <b-card>
        <b-tabs pills vertical>
            <b-tab active>
                <template #title>
                    <feather-icon icon="UserIcon" size="18" class="mr-50"/>
                    <span class="font-weight-bold">Genel Bilgiler</span>
                </template>

                <account-general v-if="options" :general-data="options"/>
            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon icon="LockIcon" size="18" class="mr-50"/>
                    <span class="font-weight-bold">Şifre Değiştir</span>
                </template>

                <account-change-password/>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import {BTabs, BTab, BCard} from 'bootstrap-vue'
import AccountGeneral from "@/views/common/account/AccountGeneral.vue";
import AccountChangePassword from "@/views/common/account/AccountChangePassword.vue";
import {toastMessage} from "@core/utils/utils";
import router from "@/router";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import storeModule from "@/views/common/account/store";
import {onUnmounted, ref} from "@vue/composition-api";

export default {
    components: {
        BCard,
        BTabs,
        BTab,
        AccountChangePassword, AccountGeneral
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const toast = useToast()
        const options = ref({})

        store.dispatch('store/fetchItem').then(response => {
            options.value = response.data.data
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'home'})
            }
        })

        return {
            options
        }
    },
}
</script>